"use client";

import { Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./components/header_footer/Footer";
import Header from "./components/header_footer/Header";
import SocialHeader from "./components/header_footer/SocialHeader";

import CertRegistrationForm from "./pages/CertRegistrationForm";
import AdminPortal from "./pages/AdminPortal";
import About from "./pages/About";
import Affiliates from "./pages/Affiliates";
import CertsTraining from "./pages/CertsTraining";
import Contact from "./pages/Contact";
import ContractsBidding from "./pages/ContractBidding";
import NoticeToContractors from "./pages/NoticeToContractors";
import Events from "./pages/Events";
import Home from "./pages/Home";
import Membership from "./pages/Membership";
import Specifications from "./pages/Specifications";
import MembershipApplication from "./pages/MemberApplication";
import Checkout from "./pages/Checkout";
import ScrollToTop from "./components/ScrollToTop";
import EventRegistration from "./pages/EventRegistration";

function App() {
  return (
    <div className="App">
      
        <ScrollToTop />
        <Header />
        <SocialHeader />
        <Routes>
          <Route index element={<Home />} />
          <Route path="mapa-admin" element={<AdminPortal />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="news" element={<Contact />} />
          <Route path="membership" element={<Membership />} />
          <Route path="specs" element={<Specifications />} />
          <Route path="events" element={<Events />} />
          <Route path="event-registration" element={<EventRegistration />} />
          <Route path="affiliates" element={<Affiliates />} />
          {/* <Route path="contracts-bidding" element={<ContractsBidding />} /> */}
          <Route
            path="notice-to-contractors"
            element={<NoticeToContractors />}
          />
          <Route path="certs-training" element={<CertsTraining />} />
          <Route path="cert-registration" element={<CertRegistrationForm />} />
          <Route
            path="member-application"
            element={<MembershipApplication />}
          />
          <Route path="checkout" element={<Checkout />} />
        </Routes>
        <Footer />
      
    </div>
  );
}

export default App;
